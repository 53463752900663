import { CoreLoggedInUser } from "../models/CoreLoggedInUser";
import { PermissionId } from "../models/CorePermission";

export const getPermissionName = (me: CoreLoggedInUser): string => {
    if (me.permissions.some(p => p.id === PermissionId.INSTANCE_ADMIN)) {
        return "Admin"
    }

    if (me.permissions.some(p => p.id === PermissionId.INSTANCE_MAPPING)) {
        return "Kopplingsbehörighet"
    }

    if (me.permissions.some(p => p.id === PermissionId.INSTANCE_READONLY)) {
        return "Läsbehörighet"
    }

    return "Obehörig";
}