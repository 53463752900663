const TOGGLEMENU = 'TOGGLEMENU';


export const ToggleMenu = (name) => dispatch => {
    dispatch({
        type: TOGGLEMENU,
        name: name
    })
}


const initialState = {
    open: {},
}

export const reducer = (state, action) => {
    state = state || initialState;
    
    switch (action.type) {
        case TOGGLEMENU:
            return {
                ...state,
                open: {
                    ...state.open,
                    [action.name]: !state.open[action.name]
                }
            }
        default:
            return state;
    }
} 