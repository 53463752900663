import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loggedInUser } from '../store/MeAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signOut } from '../store/AuthAction';
import * as FlowSyncPermission from '../util/FlowSyncPermission';

class Top extends Component {
    state = { showInfo: false }

    componentDidMount() {
        this.props.loggedInUser();
    }

    componentDidUpdate() {
        document.title = `${this.props.me.customer} - FlowSync`;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.hideInfo, { once: true })
        document.removeEventListener('click', this.checkElement);
    }

    showUserInfo = () => {
        if (this.state.showInfo) {
            this.hideInfo();
            return;
        }

        this.setState({ showInfo: true })
        window.addEventListener('scroll', this.hideInfo, {once: true})
        document.addEventListener('click', this.checkElement)
    }

    checkElement = (e) => {
        let t = e.target;
        let hideInfo = true;
        while (t) {
            if (t.id === 'user-info') {
                hideInfo = false;
                break;
            }
            t = t.parentElement;
        }

        if (hideInfo) {
            this.hideInfo();
        }
    }

    hideInfo = () => {
        this.setState({ showInfo: false });
        window.removeEventListener('scroll', this.hideInfo, { once: true })
        document.removeEventListener('click', this.checkElement);
    }

    render() {
        const { me } = this.props;

        return <div className="top">
            {me !== undefined && <React.Fragment>
                <div>{me.customer}</div>
                <div className="user" id="user-info">
                    <div className="flex">
                        <div className="displayname">{me.displayName} </div>
                        <div className="icon_box"><FontAwesomeIcon onClick={this.showUserInfo} icon="user" /></div>
                    </div>
                    {this.state.showInfo &&
                        <div className="user-info">
                            <div>{me.email}</div>
                            <div>{FlowSyncPermission.getPermissionName(me)}</div>
                        <div className="log_out" onClick={() => this.props.signOut(this.props.authAuthority)}><FontAwesomeIcon icon="sign-in-alt" className="nav_icon" />Logga ut</div>
                        </div>
                    }
                </div>
            </React.Fragment>}
        </div>;
    }
}

const mapStateToProps = state => ({
    me: state.me.me,
    authAuthority: state.auth.authority
});

export default connect(mapStateToProps, { loggedInUser, signOut })(Top);
