import React, { ChangeEventHandler } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { OptionItem, OnChangeCallback } from '../../util/UtilityTypes'

interface EnumInputProps {
    title: string
    titleSmall?: boolean
    multi?: boolean
    invalid?: boolean
    invalidFeedback?: string
    name: string
    value: number
    onChange: OnChangeCallback<number>
    options: OptionItem<number>[]
    disabled?: boolean
}

const EnumInput: React.FC<EnumInputProps> = (props) => {
    const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const checked = e.target.checked;
        const name = e.target.name;
        const targetValue = parseInt(e.target.value, 10);
        let value = props.value

        if (props.multi) {
            if (checked) {
                value = value + targetValue;
            }
            if (!checked) {
                value = value - targetValue;
            }
        } else {
            value = targetValue
        }

        props.onChange(name, value);
    }

    return (
        <FormGroup tag="fieldset">
            {props.titleSmall ? <Label>{props.title}</Label> : <h5>{props.title}</h5>}
            {props.options.map(opt => (
                <FormGroup check key={opt.value}>
                    {props.multi && <Input type="checkbox" id={`${props.name}-${opt.value}`} name={props.name} value={opt.value} checked={(props.value & opt.value) > 0} disabled={props.disabled || opt.disabled} invalid={props.invalid} onChange={onChange} />}
                    {!props.multi && <Input type="radio" id={`${props.name}-${opt.value}`} name={props.name} value={opt.value} checked={props.value === opt.value} disabled={props.disabled || opt.disabled} invalid={props.invalid} onChange={onChange} />}
                    <Label for={`${props.name}-${opt.value}`} check>{opt.text}</Label>
                </FormGroup>
            ))}
            {props.invalid && <FormFeedback style={{ display: 'block' }}>{props.invalidFeedback}</FormFeedback>}
        </FormGroup>
    )
}

export default EnumInput;