import { IDictionary } from './UtilityTypes'

export const Flowsync: IDictionary<string, string> = {
    SourceName: "Flowsync",
    User: "User",
    Module: "Module",
    Group: "Group",
    Activity: "Activity",
    ScheduleUser: "ScheduleUser",
    ScheduleResource: "ScheduleResource",
    ScheduleEvent: "ScheduleEvent",
    OneRosterClass: "OneRosterClass",
};
