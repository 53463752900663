//TODO: När route kommer tillbaka med usePrompt eller useBlock, fråga användaren om de vill spara innan de lämnar sidan.
//Problem i router v.6.14 Det går inte att stoppa routern från att navigera

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../misc/Loading'
import { getModule, updateModule, resetUpdateModule, resetGetModule } from '../../store/ModuleAction';
import StatusCode from '../../util/StatusCode';
import ModuleConfiguration from './components/ModuleConfiguration';
import BaseConfiguration from './components/BaseConfiguration';
import SchoolUnitsConfiguration from './components/SchoolUnitsConfiguration';
import ModuleType from '../misc/ModuleType';
import { useParams } from 'react-router-dom'


const ModuleView = () => {
    const dispatch = useDispatch();
    const { moduleId } = useParams();

    const module = useSelector(state => state.module.module);
    const updatedModule = useSelector(state => state.module.updatedModule);
    const isAdmin = useSelector(state => state.me.isAdmin);

    const [updates, setUpdates] = useState({});
    const [mergedModule, setMergedModule] = useState();
    const [invalid, setInvalid] = useState({});
    const [warnings, setWarnings] = useState({});

    useEffect(() => {
        getModule(moduleId, true)(dispatch)
        return () => {
            resetUpdateModule()(dispatch);
            resetGetModule()(dispatch);
        }
    }, [dispatch]);

    useEffect(() => {
        if (module.code === StatusCode.COMPLETE) {
            setMergedModule(module.data);
        }
    }, [module]);

    const update = (value) => {
        setUpdates({ ...updates, ...value.updates });
        setWarnings({ ...warnings, ...value.warnings });
    }

    const validation = (value) => {
        setInvalid({ ...invalid, ...value });
    }

    const merge = (updated) => {
        setMergedModule({ ...mergedModule, ...updated });
    }

    const saveUpdates = async () => {
        const res = await updateModule(mergedModule)(dispatch);
        await getModule(moduleId, false)(dispatch);
        if (res.status === 200) {
            setUpdates({});
        }
    }

    const isActive = () => {
        if (updates.baseModule && updates.baseModule.active !== undefined && updates.baseModule.active !== null) {
            return updates.baseModule.active;
        }

        if (module.code === StatusCode.COMPLETE) {
            return module.data.active;
        }

        return false;
    }

    const selectSchoolUnitsList = (module) => {
        switch (module.moduleType) {
            case ModuleType.Import: return module.importSchoolUnits;
            case ModuleType.Export: return module.exportSchoolUnits;
            default: return [];
        }
    }

    const renderSaveStatus = () => {
        switch (updatedModule.code) {
            case StatusCode.PENDING: return <Loading />;
            case StatusCode.ERROR: return <>
                <FontAwesomeIcon icon="exclamation-triangle" style={{ fontSize: '22px', verticalAlign: 'middle', color: '#ffc107' }} />
                <span style={{ marginLeft: '0.5rem' }}>Något gick fel</span>
            </>;
            case StatusCode.COMPLETE: return <>
                <FontAwesomeIcon icon="check" style={{ fontSize: '22px', verticalAlign: 'middle', color: '#27807d' }} />
                <span style={{ marginLeft: '0.5rem' }}>Förändringarna är sparade</span>
            </>;
            default: return null;
        }
    }

    const renderWarnings = () => {
        const renderWarnings = Object.values(warnings);

        if (renderWarnings.some(category => Object.keys(category).length > 0)) {
            return <div style={{ color: 'red' }}>
                <p>Varning!</p>
                {renderWarnings.map(category => category.map((message, index) => <p key={index}>{message}.</p>))}
            </div>
        }

        return null;
    }

    const renderModule = () => {
        const active = isActive();
        const isInvalid = Object.values(invalid).some(value => value)
        const hasUpdates = Object.values(updates).some(value => Object.keys(value).length > 0)
        const disableSave = isInvalid || !hasUpdates || !isAdmin;

        if (module.code === StatusCode.PENDING || mergedModule === undefined) {
            return <Loading />;
        } else if (module.code === StatusCode.ERROR) {
            return module.error.message;
        }
        
        return <>
            <BaseConfiguration
                baseModule={module.data}
                updates={updates.baseModule || {}}
                onUpdate={updated => update(updated)}
                mergeUpdate={updated => merge(updated)}
                invalid={value => validation(value)}
                appToken={module.data.appToken}
                active={active}
                isAdmin={isAdmin}
                id={moduleId}
                runnable={module.data.runnable}
            />

            <ModuleConfiguration
                type={module.data.moduleTypeName}
                module={module.data}
                active={active}
                updates={updates}
                onUpdate={updated => update(updated)}
                mergeUpdate={updated => merge(updated)}
                invalid={value => validation(value)}
                isAdmin={isAdmin}
                schoolUnits={module.data.exportSchoolUnits}
            />

            <SchoolUnitsConfiguration
                moduleType={module.data.moduleType}
                schoolUnits={selectSchoolUnitsList(module.data)}
                updates={updates.schoolUnits || []}
                onUpdate={updated => update(updated)}
                invalid={value => validation(value)}
                mergeUpdate={updated => merge(updated)}
                isAdmin={isAdmin}
                showSchoolUnits={module.data.selectSchoolUnits}
            />

            <div className="edit_module">
                {active && renderWarnings()}
                <Button color="primary" disabled={disableSave} onClick={saveUpdates}>
                    Spara
                </Button>
                <div style={{ marginLeft: '1rem', display: 'inline-block' }}>
                    {renderSaveStatus()}
                </div>
            </div>
        </>;
    }

    return <div className="mt-4">{renderModule()}</div>;
}

export default ModuleView;

