import React, { useState, useEffect, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import StatusCode from '../../../../../../util/StatusCode';
import Loading from '../../../../../misc/Loading';
import { GetDnpActivities, GetActivitiesBySchool, ClearActivitiesBySchool, GetMappedDnpActivities, SaveActivityMappings, GetDnpSchoolTypes, BeginSaveActivityMappings, DnpState, SaveActivityMappingsState, AbortSaveActivityMappings, ResetSaveActivityMappings } from '../../../../../../store/DnpAction';
import UnmappedDnpActivities from './UnmappedDnpActivities';
import MappedDnpActivities from './MappedDnpActivities';
import DnpActivitiesFilter from './DnpActivitiesFilter';
import { useParams, useSearchParams } from 'react-router-dom'
import RequestParser from '../../../../../../util/RequestParser';
import DnpActivitiesFooter from './DnpActivitiesFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { equvivalent } from '../../../../../../util/Helper';
import { CoreDnpActivityListing, DnpSchoolType, CoreDnpActivityMappingView, CoreDnpExportActivityMapping, DnpActivity } from '../../../../../../models/CoreDnpModels'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MeState } from '../../../../../../store/MeAction';
import { CoreLoggedInUser } from '../../../../../../models/CoreLoggedInUser';
import { PermissionId } from '../../../../../../models/CorePermission';
import { EmptyRequestState, RequestState, CacheRequestState } from '../../../../../../models/RequestState';
import { PageResult } from '../../../../../../models/CoreModels';
import ModalComponent from '../../../../../misc/ModalComponent';

const tabsValue = {
    MAPPED: 'mapped',
    UNMAPPED: 'unmapped',
    INCORRECT: 'incorrect',
};

interface DnpActivitiesState {
    dnp: DnpState
    me: MeState
}

const DnpActivitiesComponent = () => {
    const dispatch = useDispatch();
    const { dnpSchoolUnitId, dnpExportId } = useParams();
    
    const [searchParams, setSearchParams] = useSearchParams({ filterType: tabsValue.MAPPED });
    const [defaultSchoolUnitType, setDefaultSchoolUnitType] = useState<string | undefined>(undefined);
    const [schoolUnitId, setSchoolUnitIds] = useState<string[] | undefined>(undefined);

    const activitiesBySchool = useSelector<DnpActivitiesState, CacheRequestState<PageResult<CoreDnpActivityListing>>>(state => state.dnp.activitiesBySchool);
    const dnpActivities = useSelector<DnpActivitiesState, RequestState<DnpActivity[]>>(state => state.dnp.dnpActivities);
    const saveActivityMappings = useSelector<DnpActivitiesState, EmptyRequestState>(state => state.dnp.saveActivityMappings);
    const coreSchoolUnit = useSelector<DnpActivitiesState, RequestState<CoreDnpActivityMappingView>>(state => state.dnp.mappedActivites);
    const isAdmin = useSelector<DnpActivitiesState, boolean>(state => state.me.isAdmin);
    const me = useSelector<DnpActivitiesState, CoreLoggedInUser | undefined>(state => state.me.me);
    const dnpSchoolTypes = useSelector<DnpActivitiesState, RequestState<DnpSchoolType[]>>(state => state.dnp.dnpSchoolTypes);
    const saveActivityMappingsState = useSelector<DnpActivitiesState, RequestState<SaveActivityMappingsState>>(state => state.dnp.saveActivityMappingsState);

    const getDefaultSchoolType = () => {
        if (!defaultSchoolUnitType) {
            const skolverketSchoolTypes: string[] = [];
            if (coreSchoolUnit.code === StatusCode.COMPLETE) {
                const schoolTypeIds = coreSchoolUnit.data.schoolUnits.map(s => s.schoolTypes.id)

                schoolTypeIds.map(singleSchoolTypeIds => {
                    if ((singleSchoolTypeIds & 0x58) > 0) {
                        skolverketSchoolTypes.push("GR");
                    }
                    if ((singleSchoolTypeIds & 0x20) > 0) {
                        skolverketSchoolTypes.push("SP");
                    }
                    if ((singleSchoolTypeIds & 0x180) > 0) {
                        skolverketSchoolTypes.push("GY");
                    }
                    if ((singleSchoolTypeIds & 0x600) > 0) {
                        skolverketSchoolTypes.push("VUXGY");
                    }
                })
            }

            const distinctSkolverketSchoolTypes = [...new Set(skolverketSchoolTypes)]

            if (distinctSkolverketSchoolTypes.length > 1) {
                return undefined;
            }
            if (distinctSkolverketSchoolTypes.length === 1) {
                setDefaultSchoolUnitType(distinctSkolverketSchoolTypes[0]);
                updateFilter("schoolType", distinctSkolverketSchoolTypes[0]);
                return distinctSkolverketSchoolTypes[0];
            }

            return distinctSkolverketSchoolTypes[0];
        }
    }

    const disabled = !isAdmin && !me?.permissions?.some(p => p.id === PermissionId.INSTANCE_MAPPING)
    var filter = RequestParser.parseDnpActivitiesSearchPageRequest(searchParams);

    const [updatedMappedActivities, setUpdatedMappedActivities] = useState<CoreDnpExportActivityMapping[]>([]);
    const [hasUpdated, setHasUpdated] = useState(false);

    if (!hasUpdated && coreSchoolUnit.code === StatusCode.COMPLETE && !updatedMappedActivities.length) {
        setHasUpdated(true);
        setUpdatedMappedActivities(coreSchoolUnit.data.dnpActivityMappings);
    };

    useEffect(() => {
        if (dnpExportId && dnpSchoolUnitId) {
            GetMappedDnpActivities(dnpExportId, dnpSchoolUnitId)(dispatch);
        }

        return () => {
            ClearActivitiesBySchool()(dispatch);
        }
    }, [dispatch, dnpExportId, dnpSchoolUnitId]);

    useEffect(() => {
        if (coreSchoolUnit.code === StatusCode.COMPLETE) {
            GetDnpActivities(coreSchoolUnit.data.dnpEnvironment)(dispatch);
            GetDnpSchoolTypes(coreSchoolUnit.data.dnpEnvironment)(dispatch);
        }
    }, [dispatch, coreSchoolUnit.code === StatusCode.COMPLETE])

    useEffect(() => {
        if (coreSchoolUnit.code === StatusCode.COMPLETE && schoolUnitId === undefined) {
            var schoolUnitIds: string[] = coreSchoolUnit.data.schoolUnits.map(x => x.schoolUnitId)
            setSchoolUnitIds(schoolUnitIds);
            updateFilter('schoolUnitIds', schoolUnitIds)
        }
    }, [coreSchoolUnit.code === StatusCode.COMPLETE, schoolUnitId]);

    useEffect(() => {
        let forceUpdate = false;
        if (saveActivityMappings.code === StatusCode.COMPLETE) {
            forceUpdate = true;
            if (coreSchoolUnit.code === StatusCode.COMPLETE) {
                setUpdatedMappedActivities(coreSchoolUnit.data.dnpActivityMappings);
            }
            ResetSaveActivityMappings(1500)(dispatch);
        }

        if (coreSchoolUnit.code === StatusCode.COMPLETE && dnpSchoolUnitId && schoolUnitId !== undefined) {
            GetActivitiesBySchool(dnpSchoolUnitId, filter, forceUpdate)(dispatch);
        }
    }, [dispatch, searchParams, saveActivityMappings, coreSchoolUnit, schoolUnitId]);

    useEffect(() => {
        if (dnpExportId && dnpSchoolUnitId && saveActivityMappingsState.code === StatusCode.COMPLETE && saveActivityMappingsState.data.allMappingsAreValid) {
            SaveActivityMappings(dnpExportId, dnpSchoolUnitId, saveActivityMappingsState.data.updatedMappedActivities)(dispatch);
        }
    }, [dispatch, dnpExportId, dnpSchoolUnitId, saveActivityMappingsState])

    const updateTab = (name: string, value: string) => {
        ClearActivitiesBySchool()(dispatch);
        updateFilter(name, value);
    }

    const updateFilter = (name: string, value: string | null | undefined | number | number[] | string[]) => {
        if (name !== 'page') {
            searchParams.set('page', '1');
        }

        if (typeof (value) === 'object') {
            if (value !== null && value.length > 0) {
                searchParams.delete(name)

                value.forEach(x => {
                    searchParams.append(name, x.toString())
                })
            } else {
                searchParams.delete(name)
            }
        }
        else if (value !== undefined && value !== '' && value !== null) {
            searchParams.set(name, value.toString());
        } else {
            searchParams.delete(name);
        }

        setSearchParams(searchParams);
    };

    const clearFilter = () => {
        if (schoolUnitId !== undefined) {
            if (filter.filterType === undefined) {
                setSearchParams({ filterType: tabsValue.UNMAPPED, schoolUnitIds: schoolUnitId });
                return;
            }

            if ((filter.schoolType !== undefined) && filter.schoolType === defaultSchoolUnitType) {
                setSearchParams({ filterType: filter.filterType, schoolType: filter.schoolType, schoolUnitIds: schoolUnitId });
                return;
            }

            setSearchParams({ filterType: filter.filterType, schoolUnitIds: schoolUnitId });
        }
    };

    const beginSaveMappings = () => {
        if (dnpExportId && dnpSchoolUnitId && coreSchoolUnit.code === StatusCode.COMPLETE) {
            BeginSaveActivityMappings(coreSchoolUnit.data.dnpEnvironment, updatedMappedActivities)(dispatch);
        }
    }

    const abortSaveMappings = () => {
        AbortSaveActivityMappings()(dispatch);
    }

    const confirmSaveMappings = () => {
        if (dnpExportId && dnpSchoolUnitId && saveActivityMappingsState.code === StatusCode.COMPLETE) {
            SaveActivityMappings(dnpExportId, dnpSchoolUnitId, saveActivityMappingsState.data.updatedMappedActivities)(dispatch);
        }
    }

    const translateSchoolType = (schoolType: string) => {
        if (dnpSchoolTypes.code === StatusCode.COMPLETE) {
            const dnpSchoolType = dnpSchoolTypes.data.find(st => st['skv:identifier'] === schoolType)?.['skv:title'];
            if (dnpSchoolType !== undefined) {
                return dnpSchoolType;
            } else {
                return schoolType;
            }
        }
        return schoolType;
    }

    const renderActivitiesTable = (header: string[], mobileHeader: string, resultsMissing: string) => {
        return (<>
            <div className={(`columns-${header.length}`) + " dnp-activities grid-table"}>
                <div className="general-header --header">
                    <span>FlowSync Aktivitet</span>
                    <span>Provaktivitet</span>
                </div>
                <div className="--header">
                    {header.map((x, i) => (
                        <span key={i}>{x}</span>))}
                </div>
                <div className="sm-header">{mobileHeader}</div>
                {renderActivities(resultsMissing)}
            </div>
        </>);
    };

    const renderActivities = (resultsMissing: string) => {
        if (!dnpExportId || !dnpSchoolUnitId) {
            return; // The default 404 page handles when all required url parameters are not present.
        } else if (!activitiesBySchool || (activitiesBySchool.code === StatusCode.PENDING && !activitiesBySchool.data?.values.length)) {
            return <Loading />;
        } else if (activitiesBySchool.code === StatusCode.COMPLETE && !activitiesBySchool.data.values.length) {
            if (filter.search || filter.syllabusId) {
                return <div className="pt-2 ps-2">Hittade inga aktiviteter med det angivna filtret</div>
            } else {
                return <div className="pt-2 ps-2">{resultsMissing}</div>
            }
        } else if (activitiesBySchool.code === StatusCode.COMPLETE
            || (activitiesBySchool.code === StatusCode.PENDING && activitiesBySchool.data?.values && activitiesBySchool.data.values.length > 1)) {
            return (<>
                {activitiesBySchool.code === StatusCode.PENDING && <Loading className="spinner-update" />}
                {
                    filter.filterType === tabsValue.UNMAPPED ?
                        <UnmappedDnpActivities
                            dnpExportId={dnpExportId}
                            dnpSchoolUnitId={dnpSchoolUnitId}
                            activitiesBySchool={activitiesBySchool}
                            dnpActivities={dnpActivities}
                            filter={filter}
                            disabled={disabled}
                            updatedMappedActivities={updatedMappedActivities}
                            onUpdate={(updatedMappedActivities) => setUpdatedMappedActivities(updatedMappedActivities)}
                        /> :
                        <MappedDnpActivities
                            dnpExportId={dnpExportId}
                            dnpSchoolUnitId={dnpSchoolUnitId}
                            activitiesBySchool={activitiesBySchool}
                            dnpActivities={dnpActivities}
                            filter={filter}
                            disabled={disabled}
                            updatedMappedActivities={updatedMappedActivities}
                            onUpdate={(updatedMappedActivities) => setUpdatedMappedActivities(updatedMappedActivities)}
                            mappedActivites={coreSchoolUnit.code === StatusCode.COMPLETE ? coreSchoolUnit.data.dnpActivityMappings : []}
                            translateSchoolType={(schoolType) => translateSchoolType(schoolType)}
                        />
                }
            </>);
        } else if (activitiesBySchool.code === StatusCode.ERROR) {
            return <span>Något gick fel! Kunde inte hämta aktiviteter</span>
        };
    };

    const confirmSaveWithInvalidMappingsModal = () => {
        if (saveActivityMappingsState.code !== StatusCode.COMPLETE) {
            return;
        }

        if (saveActivityMappingsState.data.allMappingsAreValid) {
            return;
        }

        return (
            <ModalComponent
                isOpen={saveActivityMappings.code === StatusCode.PENDING}
                toggleModal={abortSaveMappings}
                header="Spara med felaktigt kopplade aktiviteter?"
                size="sm"
                cancel="Nej"
                confirm="Ja"
                update={confirmSaveMappings}
            >
                <p>Det finns felaktigt kopplade aktiviteter kvar att hantera. Det går inte att genomföra en export till DNP om det finns felaktiga aktivtetskopplingar. Vill du spara ändå?</p>
            </ModalComponent>
        )
    }

    const tabs = () => {
        const hasInvalid = coreSchoolUnit.code === StatusCode.COMPLETE && coreSchoolUnit.data.dnpActivityMappings.some(x => !x.valid);
        let incorrectTitle: ReactNode = "Felaktigt kopplade aktiviteter";
        if (hasInvalid) {
            incorrectTitle = (<span>Felaktigt kopplade aktiviteter <FontAwesomeIcon color={'orangered'} icon={'triangle-exclamation'} /></span>)
        }

        return (
            <div className="tabs">
                {tab(tabsValue.MAPPED, "Kopplade aktiviteter", 'check', undefined)}
                {tab(tabsValue.UNMAPPED, "Ej kopplade aktiviteter", 'exclamation', undefined)}
                {tab(tabsValue.INCORRECT, incorrectTitle, 'triangle-exclamation', hasInvalid ? 'orangered' : undefined)}
            </div>);
    };

    const tab = (tabsValue: string, title: ReactNode, icon: IconProp, color: string | undefined) => (<>
        <button className={(filter.filterType === tabsValue ? "active " : '') + "tab lg"} onClick={() => updateTab('filterType', tabsValue)}>{title}</button>
        <button className={(filter.filterType === tabsValue ? "active " : '') + "tab sm"} onClick={() => updateTab('filterType', tabsValue)}><FontAwesomeIcon color={color} icon={icon} /></button>
    </>);

    const renderBody = () => {
        switch (filter.filterType) {
            case tabsValue.MAPPED:
                return renderActivitiesTable(['Namn', 'Skola', 'Årskurs', 'Startdatum', 'Slutdatum', 'Kurs/ämne', 'Provaktivitet','Status', ''], "Kopplade aktiviteter", "Det finns inga kopplade aktiviteter för den här skolan");
            case tabsValue.UNMAPPED:
                return renderActivitiesTable(['Namn', 'Skola', 'Årskurs', 'Startdatum', 'Slutdatum', 'Kurs/ämne', 'Provaktivitet'], "Ej kopplade aktiviteter", "Aktiviteter saknas för den här skolan");
            case tabsValue.INCORRECT:
                return renderActivitiesTable(['Namn', 'Skola', 'Årskurs', 'Startdatum', 'Slutdatum', 'Kurs/ämne', 'Provaktivitet', 'Status', ''], "Felaktigt kopplade aktiviteter", "Det finns inga felaktigt kopplade aktiviteter för den här skolan");
            default: return;
        };
    };

    const header = coreSchoolUnit.code === StatusCode.COMPLETE && `: ${coreSchoolUnit.data.dnpSchoolUnitDisplayName} -
        ${coreSchoolUnit.data?.schoolUnits?.length > 0 && coreSchoolUnit.data?.schoolUnits.map(s => s.schoolUnitDisplayName).join(', ')}`;

    return (<>
        <div className="dnp-container">
            <h2 className="mb-4">Aktivitetskoppling{header} <a href="https://flowsync.se/help/digitala-nationella-prov#aktivitetskoppling" target="_blank"><FontAwesomeIcon className="documentation-icon fs-color" title="Visa hjälpavsnitt" icon="question-circle" /></a></h2>

            {dnpActivities.code === StatusCode.ERROR &&
                <div style={{ border: '2px solid orangered', padding: '1rem', marginBottom: '1rem' }}>Hämtningen av provaktiviteter från Skolverkets provtjänst fungerar inte för tillfället. Kopplingar kan inte genomföras.</div>}

            {dnpActivities && coreSchoolUnit.code === StatusCode.COMPLETE &&
                <DnpActivitiesFilter
                    environment={coreSchoolUnit.data.dnpEnvironment}
                    dnpActivities={dnpActivities}
                    updateFilter={(name, value) => updateFilter(name, value)}
                    schoolUnitIds={schoolUnitId}
                    clearFilter={clearFilter}
                    filter={filter}
                    translateSchoolType={(schoolType) => translateSchoolType(schoolType)}
                    defaultSchoolType={getDefaultSchoolType()}
                    dnpSchoolUnitId={dnpSchoolUnitId}
                    schoolUnits={coreSchoolUnit.code === StatusCode.COMPLETE ? coreSchoolUnit.data.schoolUnits : []}
                />}
            {tabs()}
            {renderBody()}
            {confirmSaveWithInvalidMappingsModal()}
        </div>
        {activitiesBySchool &&
            <DnpActivitiesFooter
                saveConfiguration={beginSaveMappings}
                updateFilter={(name, value) => updateFilter(name, value)}
                saveStatus={saveActivityMappings.code}
                disabledSave={equvivalent(updatedMappedActivities, (coreSchoolUnit.code === StatusCode.COMPLETE ? coreSchoolUnit.data.dnpActivityMappings : []))}
                requestState={activitiesBySchool}
            />
        }
    </>);
}

export default DnpActivitiesComponent;
