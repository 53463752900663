import React, { ReactNode, useEffect } from 'react';
import Loading from '../misc/Loading';
import Login from './Login';
import Unauthorized from './Unauthorized';
import { AuthState, silentAuth } from '../../store/AuthAction';
import { getMyModuleTypes, ModuleState } from '../../store/ModuleAction';
import StatusCode from '../../util/StatusCode';
import { useDispatch, useSelector } from 'react-redux';
import { Result } from '../../models/CoreModels';
import { MyModuleTypes } from '../../models/CoreDashboardModels';

interface PrivateProps {
    children: ReactNode[]
}

interface PrivateState {
    auth: AuthState
    module: ModuleState
}

const Private: React.FC<PrivateProps> = (props) => {
    const dispatch = useDispatch();

    const needLogin = useSelector<PrivateState, boolean>(state => state.auth.needLogin);
    const userAuthorized = useSelector<PrivateState, boolean | undefined>(state => state.auth.userAuthorized);
    const myModuleTypes = useSelector<PrivateState, Result<MyModuleTypes>>(state => state.module.myModuleTypes);

    useEffect(() => {
        if (userAuthorized === undefined) {
            silentAuth()(dispatch);
        }
        if (userAuthorized && myModuleTypes.code === StatusCode.NONE) {
            getMyModuleTypes(true)(dispatch);
        }
    }, [dispatch, userAuthorized, myModuleTypes]);

    if (needLogin) {
        return <Login />;
    } else if (userAuthorized === undefined && (myModuleTypes.code === StatusCode.NONE || myModuleTypes.code === StatusCode.PENDING)) {
        return <Loading style={{ padding: '40px' }} />;
    } else if (!userAuthorized) {
        return <Unauthorized />
    }

    return <>{props.children}</>
}

export default Private;
