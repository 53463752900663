import React, { useRef, useState } from 'react';
import * as Helper from '../../../util/Helper';
import TableHorizontal, { InfoObject, Label } from '../../misc/TableHorizontal';
import { Button, Col, Row, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, UncontrolledTooltip } from 'reactstrap';
import ModalComponent from '../../misc/ModalComponent';
import StatusCode from '../../../util/StatusCode';
import { Link } from 'react-router-dom';
import { RequestState } from '../../../models/RequestState';
import { CoreTracking, CoreTrackingModule, SourceObjectOwnership, TrackingOwnership } from '../../../models/CoreTracking';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Copy from '../../misc/Copy';

interface TrackingsModuleProps {
    trackings: RequestState<CoreTracking[]>
    noResMsg: string
}

const trackingTableHeader: Label[] = [
    { value: 'Källa' },
    { value: 'Miljöid' },
    { value: 'Objekttyp' },
    { value: 'Identifierare' },
    { value: 'Status' },
    { value: 'Skapad' },
    { value: 'Ändrad' },
]

const getTrackingStatus = (tracking: CoreTracking) => {
    if (tracking.sourceObjectOwnership === SourceObjectOwnership.PRIMARY) {
        let inactiveStatus = '';
        if (!tracking.active) {
            inactiveStatus = ', Inaktiv';
        }

        if (tracking.deleted !== undefined) {
            return 'Borttagen' + inactiveStatus
        }

        if (tracking.archived !== undefined) {
            return 'Arkiverad' + inactiveStatus
        }

        if (tracking.inactivated !== undefined) {
            return 'Inväntar arkivering' + inactiveStatus
        }
    }

    if (tracking.active) {
        return 'Aktiv';
    }

    return 'Inaktiv';
}

const DateColumn: React.FC<{ date: string }> = props => {
    var ref = useRef(null);
    var formattedDateOnly = Helper.formatDateOnly(props.date); 
    return <>
        <span className="text-nowrap" ref={ref}>
            {formattedDateOnly}
        </span>
        {formattedDateOnly !== '-' &&
            <UncontrolledTooltip target={ref} autohide={false} placement="top-start" className="mr-3" onClick={e => e.stopPropagation()}>
                {Helper.formatDate(props.date)}
            </UncontrolledTooltip>
        }
    </>;
}

const compareTrackingModules = (a: CoreTrackingModule, b: CoreTrackingModule) => {
    if (a.active && !b.active) {
        return -1;
    } else if (b.active && !a.active) {
        return 1;
    } else {
        return b.trackingOwnership - a.trackingOwnership;
    }
}

const TrackingsModule: React.FC<TrackingsModuleProps> = (props: TrackingsModuleProps) => {
    const [open, setOpen] = useState(false);
    const [metaData, setMetaData] = useState<string | undefined>(undefined);

    const renderModuleList = (modules: CoreTrackingModule[]) => modules
        .sort(compareTrackingModules)
        .map((module) => {
            const iconKey = `statusIcon_${module.moduleId}`;

            let icon: JSX.Element;
            if (!module.active) {
                icon = (<FontAwesomeIcon key={iconKey} title="Inaktiv" color="gray" icon={['far', 'circle']} />);
            } else if (module.trackingOwnership === TrackingOwnership.PRIMARY) {
                icon = (<FontAwesomeIcon key={iconKey} title="Ägare" color="green" icon={['fas', 'check-circle']} />);
            } else if (module.trackingOwnership === TrackingOwnership.POTENTIAL) {
                icon = (<FontAwesomeIcon key={iconKey} title="Potentiell ägare" color="green" icon={['far', 'check-circle']} />);
            } else {
                icon = (<FontAwesomeIcon key={iconKey} title="Kan inte äga" color="green" icon={['far', 'circle']} />);
            }

            const link = (<Link key={module.moduleId} to={`/module/${module.moduleId}/status`}>{module.moduleName}</Link>);

            return <div><span className="me-2">{icon}</span> {link}</div>;
        });

    const mapTrackings = (): InfoObject[] => {
        if (props.trackings.code !== StatusCode.COMPLETE) {
            return [];
        }

        const infoObjects = props.trackings.data.map<InfoObject>((tracking) => ({
            id: tracking.trackingId,
            data: [
                { value: tracking.sourceName },
                { value: tracking.sourceEnvironment },
                { value: tracking.sourceObjectType },
                { value: <div>{tracking.sourceSystemId} <Copy value={tracking.sourceSystemId} /></div> },
                { value: getTrackingStatus(tracking) },
                { value: <DateColumn date={tracking.created} /> },
                { value: <DateColumn date={tracking.modified} /> },
            ],
            expandableContent: (<div className="pb-3">
                <TableHorizontal
                    className='m-0 mini'
                    header={[
                        { value: 'Moduler' },
                        { value: 'Metadata' },
                        { value: 'Inaktiverad' },
                        { value: 'Arkiverad' },
                        { value: 'Borttagen' },
                        { value: 'Nästa analys', className: "text-nowrap" },
                    ]}
                    info={[{
                        id: '0',
                        data: [
                            { value: renderModuleList(tracking.modules) },
                            { value: tracking.metadata ? <Button size="sm" title="Visa metadata" className="text-nowrap" onClick={() => (setOpen(true), setMetaData(tracking.metadata))}>Visa metadata</Button> : '-' },
                            { value: <DateColumn date={tracking.inactivated} /> },
                            { value: <DateColumn date={tracking.archived} /> },
                            { value: <DateColumn date={tracking.deleted} /> },
                            { value: <DateColumn date={tracking.nextUpdateCheck} /> },
                        ]
                    }]}
                    searching={false}
                    error={false}
                />
            </div>)
        }))

        return infoObjects;
    }

    return (<div>
        <h5 className="header-menu">Källor</h5>
        
        <TableHorizontal
            header={trackingTableHeader}
            info={mapTrackings()}
            searching={props.trackings.code === StatusCode.PENDING}
            error={props.trackings.code === StatusCode.ERROR}
            noResMsg={props.noResMsg}
        />

        {open && metaData !== undefined &&
            <ModalComponent
                isOpen={open}
                toggleModal={() => (setOpen(false), setMetaData(undefined))}
                header="Felsökningsinformation"
                size="xl"
                cancel="Stäng"
            >
                <div>
                    <pre>{Helper.formatText(metaData)}</pre>
                </div>
            </ModalComponent>}
    </div>);
}

export default TrackingsModule;
