import React from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardBody, Collapse, Button } from 'reactstrap';
import { getModules } from '../../../../store/ModuleAction';
import ConfigurationComponent from '../ConfigurationComponent';
import GoogleUserExportStudent from './components/GoogleUserExport/GoogleUserExportStudent';
import GoogleUserExportStaff from './components/GoogleUserExport/GoogleUserExportStaff';
import GoogleUserReportRecipients from './components/GoogleUserExport/GoogleUserReportRecipients';
import * as GoogleUser from '../../../../util/GoogleUserExportTypes'
import StatusCode from '../../../../util/StatusCode';

const bitCount = (i) => {
    i = i - ((i >> 1) & 0x55555555);
    i = (i & 0x33333333) + ((i >> 2) & 0x33333333);
    i = (i + (i >> 4)) & 0x0F0F0F0F;
    return (i * 0x01010101) >> 24;
}

const validateStudentConfig = (studentConfig, validation) => {
    //lifecyclemanagement is always configurable
    if (Number.isNaN(studentConfig.deleteAfterDays)) {
        validation.push('studentConfiguration.deleteAfterDays');
    }

    if (!studentConfig.enabled) {
        return;
    }

    if (studentConfig.displayNamePolicy.suffix === GoogleUser.DisplayNameSuffixType.NONE) {
        validation.push('studentConfiguration.displayNamePolicy.suffix');
    }

    if (studentConfig.emailAddressPolicy.type === GoogleUser.EmailAddressPolicyType.GENERATED) {
        let totalCharacterCount = 0;
        if (studentConfig.emailAddressPolicy.includeGivenName) {
            if (studentConfig.emailAddressPolicy.givenNameCharacterCount !== null) {
                if (Number.isNaN(studentConfig.emailAddressPolicy.givenNameCharacterCount)
                    || studentConfig.emailAddressPolicy.givenNameCharacterCount < 1) {
                    validation.push('studentConfiguration.emailAddressPolicy.givenNameCharacterCount');
                }
                else {
                    totalCharacterCount += studentConfig.emailAddressPolicy.givenNameCharacterCount;
                }
            }
            else {
                totalCharacterCount += 100;
            }
        }

        if (studentConfig.emailAddressPolicy.includeFamilyName) {
            if (studentConfig.emailAddressPolicy.familyNameCharacterCount !== null) {
                if (Number.isNaN(studentConfig.emailAddressPolicy.familyNameCharacterCount)
                    || studentConfig.emailAddressPolicy.familyNameCharacterCount < 1) {
                    validation.push('studentConfiguration.emailAddressPolicy.familyNameCharacterCount');
                }
                else {
                    totalCharacterCount += studentConfig.emailAddressPolicy.familyNameCharacterCount;
                }
            }
            else {
                totalCharacterCount += 100;
            }
        }

        if ((!studentConfig.emailAddressPolicy.includeFamilyName && !studentConfig.emailAddressPolicy.includeGivenName) || totalCharacterCount < 4) {
            validation.push('studentConfiguration.emailAddressPolicy.nameCharacterTotalCount');
        }

        if (studentConfig.emailAddressPolicy.serialNumberDelimiter === '') {
            validation.push('studentConfiguration.emailAddressPolicy.serialNumberDelimiter');
        }
    }

    if (studentConfig.emailAddressPolicy.type === GoogleUser.EmailAddressPolicyType.ANONYMOUS) {
        if (studentConfig.emailAddressPolicy.randomCharacterCount < 0) {
            validation.push('studentConfiguration.emailAddressPolicy.randomCharacterCount');
        }
        if (studentConfig.emailAddressPolicy.randomNumberCount < 0) {
            validation.push('studentConfiguration.emailAddressPolicy.randomNumberCount');
        }
        if ((studentConfig.emailAddressPolicy.randomCharacterCount +
            studentConfig.emailAddressPolicy.randomNumberCount) < 6) {
            validation.push('studentConfiguration.emailAddressPolicy.randomTotalCount');
        }
    }

    if (studentConfig.emailAddressPolicy.domainName === '') {
        validation.push('studentConfiguration.emailAddressPolicy.domainName');
    }

    if (studentConfig.passwordPolicy.type === GoogleUser.PasswordPolicyType.RANDOM) {
        if (studentConfig.passwordPolicy.minLength < 8) {
            validation.push('studentConfiguration.passwordPolicy.minLength');
        }

        if ((studentConfig.passwordPolicy.maxLength < studentConfig.passwordPolicy.minLength
            || studentConfig.passwordPolicy.maxLength < 8) || studentConfig.passwordPolicy.maxLength > 100) {
            validation.push('studentConfiguration.passwordPolicy.maxLength');
        }

        if (bitCount(studentConfig.passwordPolicy.passwordCharacterSets) < 2) {
            validation.push('studentConfiguration.passwordPolicy.passwordCharacterSets');
        }
    }

    if (!studentConfig.passwordPolicy.useMonthAndDayFromSocialnumber &&
        studentConfig.passwordPolicy.staticValue.length < 8) {
        validation.push('studentConfiguration.passwordPolicy.staticValue');
    }
    if (studentConfig.passwordPolicy.useMonthAndDayFromSocialnumber &&
        studentConfig.passwordPolicy.staticValue.length < 4) {
        validation.push('studentConfiguration.passwordPolicy.staticValue');
    }
};

const validateStaffConfig = (staffConfig, validation) => {

    //lifecyclemanagement is always configurable
    if (Number.isNaN(staffConfig.deleteAfterDays)) {
        validation.push('staffConfiguration.deleteAfterDays');
    }

    if (!staffConfig.enabled) {
        return;
    }

    //DisplayName
    if (staffConfig.displayNamePolicy.suffix === GoogleUser.DisplayNameSuffixType.NONE) {
        validation.push('staffConfiguration.displayNamePolicy.suffix');
    }

    //EmailPolicy
    if (staffConfig.emailAddressPolicy.type === GoogleUser.EmailAddressPolicyType.GENERATED) {
        let totalCharacterCount = 0;
        if (staffConfig.emailAddressPolicy.includeGivenName) {
            if (staffConfig.emailAddressPolicy.givenNameCharacterCount !== null) {
                if (Number.isNaN(staffConfig.emailAddressPolicy.givenNameCharacterCount)
                    || staffConfig.emailAddressPolicy.givenNameCharacterCount < 1) {
                    validation.push('staffConfiguration.emailAddressPolicy.givenNameCharacterCount');
                }
                else {
                    totalCharacterCount += staffConfig.emailAddressPolicy.givenNameCharacterCount;
                }
            }
            else {
                totalCharacterCount += 100;
            }
        }

        if (staffConfig.emailAddressPolicy.includeFamilyName) {
            if (staffConfig.emailAddressPolicy.familyNameCharacterCount !== null) {
                if (Number.isNaN(staffConfig.emailAddressPolicy.familyNameCharacterCount)
                    || staffConfig.emailAddressPolicy.familyNameCharacterCount < 1) {
                    validation.push('staffConfiguration.emailAddressPolicy.familyNameCharacterCount');
                }
                else {
                    totalCharacterCount += staffConfig.emailAddressPolicy.familyNameCharacterCount;
                }
            }
            else {
                totalCharacterCount += 100;
            }
        }

        if ((!staffConfig.emailAddressPolicy.includeFamilyName && !staffConfig.emailAddressPolicy.includeGivenName) || totalCharacterCount < 4) {
            validation.push('staffConfiguration.emailAddressPolicy.nameCharacterTotalCount');
        }

        if (staffConfig.emailAddressPolicy.serialNumberDelimiter === '') {
            validation.push('staffConfiguration.emailAddressPolicy.serialNumberDelimiter');
        }
    }

    if (staffConfig.emailAddressPolicy.type === GoogleUser.EmailAddressPolicyType.ANONYMOUS) {
        if (staffConfig.emailAddressPolicy.randomCharacterCount < 0) {
            validation.push('staffConfiguration.emailAddressPolicy.randomCharacterCount');
        }
        if (staffConfig.emailAddressPolicy.randomNumberCount < 0) {
            validation.push('staffConfiguration.emailAddressPolicy.randomNumberCount');
        }
        if ((staffConfig.emailAddressPolicy.randomCharacterCount +
            staffConfig.emailAddressPolicy.randomNumberCount) < 6) {
            validation.push('staffConfiguration.emailAddressPolicy.randomTotalCount');
        }
    }

    if (staffConfig.emailAddressPolicy.domainName === '') {
        validation.push('staffConfiguration.emailAddressPolicy.domainName');
    }

    //Password
    if (staffConfig.passwordPolicy.type === GoogleUser.PasswordPolicyType.RANDOM) {
        if (staffConfig.passwordPolicy.minLength < 8) {
            validation.push('staffConfiguration.passwordPolicy.minLength');
        }

        if ((staffConfig.passwordPolicy.maxLength < staffConfig.passwordPolicy.minLength
            || staffConfig.passwordPolicy.maxLength < 8) || staffConfig.passwordPolicy.maxLength > 100) {
            validation.push('staffConfiguration.passwordPolicy.maxLength');
        }

        if (bitCount(staffConfig.passwordPolicy.passwordCharacterSets) < 2) {
            validation.push('staffConfiguration.passwordPolicy.passwordCharacterSets');
        }
    }

    if (!staffConfig.passwordPolicy.useMonthAndDayFromSocialnumber &&
        staffConfig.passwordPolicy.staticValue.length < 8) {
        validation.push('staffConfiguration.passwordPolicy.staticValue');
    }
    if (staffConfig.passwordPolicy.useMonthAndDayFromSocialnumber &&
        staffConfig.passwordPolicy.staticValue.length < 4) {
        validation.push('staffConfiguration.passwordPolicy.staticValue');
    }
};

class GoogleUserExport extends ConfigurationComponent {
    state = { open: true }

    componentDidMount() {
        this.props.getModules();
    }

    validate(configuration) {
        const validation = [];

        const studentConfig = configuration.studentConfiguration;
        const staffConfig = configuration.staffConfiguration;

        validateStudentConfig(studentConfig, validation);
        validateStaffConfig(staffConfig, validation);

        return validation;
    }

    warnings(update) {
        const warningMessages = [];
        if (update.staffConfiguration?.enabled === false) {
            warningMessages.push('Om du inaktiverar synk av personalkonton kommer alla personalkonton som redan synkats att inaktiveras')
        }

        if (update.studentConfiguration?.enabled === false) {
            warningMessages.push('Om du inaktiverar synk av elevkonton kommer alla elevkonton som redan synkats att inaktiveras')
        }

        return warningMessages;
    }

    render() {
        let configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration);

        let importModuleOptions = [];
        if (this.props.modules && this.props.modules.code === StatusCode.COMPLETE) {
            importModuleOptions = this.props.modules.data
                .filter(x => x.moduleType === 1)
                .map(x => x.moduleTypeName)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map(x => ({
                    value: x,
                    text: x,
                }));
        }

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>

                        <GoogleUserExportStudent
                            configuration={configuration.studentConfiguration}
                            disableInput={this.props.disableInput}
                            validation={validation}
                            update={this.updatePropertyValue}
                            configurationType="studentConfiguration"
                            sourceSystemOptions={importModuleOptions}
                        />

                        <GoogleUserExportStaff
                            configuration={configuration.staffConfiguration}
                            disableInput={this.props.disableInput}
                            validation={validation}
                            update={this.updatePropertyValue}
                            configurationType="staffConfiguration"
                            sourceSystemOptions={importModuleOptions}
                        />

                        <GoogleUserReportRecipients
                            reportRecipients={configuration.reportRecipients}
                            disableInput={this.props.disableInput}
                            onChange={(this.updatePropertyValue)}
                        />

                    </CardBody>
                </Collapse>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    modules: state.module.modules,
});

export default connect(mapStateToProps, { getModules })(GoogleUserExport);
