import { Action, Reducer, Dispatch } from 'redux';
import { CoreLoggedInUser } from '../models/CoreLoggedInUser';
import { PermissionId } from '../models/CorePermission';
import InstanceClient from '../services/InstanceClient';

export enum MeActionType {
    GET_ME = 'GET_ME'
}

export interface MeState {
    me?: CoreLoggedInUser,
    isAdmin: boolean
}

interface GetMeAction extends Action<MeActionType.GET_ME> {
    me: CoreLoggedInUser
}

type MeAction = GetMeAction

const initialState: MeState = {
    me: undefined,
    isAdmin: false
};

export const loggedInUser = () => (dispatch: Dispatch<GetMeAction>) => {
    const url = '/api/core/me';
    InstanceClient.get<CoreLoggedInUser>(url)
        .then(res => {
            dispatch({
                type: MeActionType.GET_ME,
                me: res.data,
            })
        });
};

export const reducer: Reducer<MeState, MeAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case MeActionType.GET_ME:
            return {
                ...state,
                me: action.me,
                isAdmin: action.me.permissions.some(p => p.id === PermissionId.INSTANCE_ADMIN)
            }
        default:
            return state;
    }
}