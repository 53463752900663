import React, { Component } from 'react';
import { NavLink, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signOut } from '../store/AuthAction';
import { ToggleMenu } from '../store/MenuAction';
import SubFooter from './SubFooter';
import NavSection from '../util/NavSection';
import StatusCode from '../util/StatusCode';
import { withRouter } from '../components/withRouter';


class NavMenu extends Component {
    state = {
        collapsed: true,
    };

    componentDidMount() {
        this.props.ToggleMenu(this.props.activeSection);
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleMenu = (name) => {
        this.props.ToggleMenu(name);
    }

    renderModule = (type) => {
        const { myModuleTypes } = this.props;

        if (myModuleTypes.code === StatusCode.COMPLETE) {
            if (myModuleTypes.data[type]) {
                return true;
            }
            return false;
        }
        return true;
    }

    activeTab = (url) => {
        return this.props.location.pathname.startsWith(url);
    }

    render() {
        const { open, isAdmin, activeSection } = this.props;
        const symbols = (name) => open[name] ? <FontAwesomeIcon icon="chevron-down" className="arrow" /> : <FontAwesomeIcon icon="chevron-right" className="arrow" />;
        const active = (x) => activeSection === x && !open[x] ? 'active' : '';

        return (
            <Navbar className="dark">
                <NavbarBrand className="mr-auto brand"><img src="/FlowSync_lg_light.svg" alt="FlowSync" className="logo" /></NavbarBrand>
                <NavbarToggler onClick={this.toggleNavbar} ><FontAwesomeIcon icon="bars" /> </NavbarToggler>
                <Collapse isOpen={!this.state.collapsed} navbar>
                    <Nav navbar key={this.props.location.key}>

                        <NavItem>
                            <NavLink tag={RouterNavLink} to="/"><FontAwesomeIcon icon="network-wired" className="nav_icon" />Dashboard</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RouterNavLink} to="/overview"><FontAwesomeIcon icon="diagram-project" className="nav_icon" />Synköversikt</NavLink>
                        </NavItem>

                        {this.renderModule('schoolAdmin') && <React.Fragment>
                            <NavItem onClick={() => this.toggleMenu(NavSection.SCHOOLADMINISTRATION)}>
                                <NavLink href="#" className={active(NavSection.SCHOOLADMINISTRATION)}>Skoladministration<span className="open">{symbols(NavSection.SCHOOLADMINISTRATION)}</span></NavLink>
                            </NavItem>
                            {open[NavSection.SCHOOLADMINISTRATION] && <React.Fragment>
                                <NavItem>
                                    <NavLink active={this.activeTab('/dashboard')} tag={RouterNavLink} to="/dashboard/" className="submenu"><FontAwesomeIcon icon="network-wired" className="nav_icon" />Översikt</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={this.activeTab('/schoolunit')} tag={RouterNavLink} to="/schoolunit/" className="submenu"><FontAwesomeIcon icon="school" className="nav_icon" />Skolor</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={this.activeTab('/activity')} tag={RouterNavLink} to="/activity/" className="submenu"><FontAwesomeIcon icon="calendar-alt" className="nav_icon" />Aktiviteter</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={this.activeTab('/group')} tag={RouterNavLink} to="/group/" className="submenu"><FontAwesomeIcon icon="users" className="nav_icon" />Grupper</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={this.activeTab('/user')} tag={RouterNavLink} to="/user/" className="submenu"><FontAwesomeIcon icon="user" className="nav_icon" />Personer</NavLink>
                                </NavItem>
                            </React.Fragment>}
                        </React.Fragment>}


                        {this.renderModule('scheduleSync') && <React.Fragment>
                            <NavItem onClick={() => this.toggleMenu(NavSection.SCHEDULESYNC)}>
                                <NavLink href="#" className={active(NavSection.SCHEDULESYNC)}>Schemapublicering<span className="open">{symbols(NavSection.SCHEDULESYNC)}</span></NavLink>
                            </NavItem>
                            {open[NavSection.SCHEDULESYNC] && <React.Fragment>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} active={this.activeTab('/schedulesync/user')} to="/schedulesync/user/" className="submenu"><FontAwesomeIcon icon="user" className="nav_icon" />Användare</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} active={this.activeTab('/schedulesync/event')} to="/schedulesync/event/" className="submenu"><FontAwesomeIcon icon="calendar-alt" className="nav_icon" />Schemapositioner</NavLink>
                                </NavItem>
                            </React.Fragment>}
                        </React.Fragment>}


                        {this.renderModule('oneRoster') && <React.Fragment>
                            <NavItem onClick={() => this.toggleMenu(NavSection.ONEROSTER)}>
                                <NavLink href="#" className={active(NavSection.ONEROSTER)}>OneRoster<span className="open">{symbols(NavSection.ONEROSTER)}</span></NavLink>
                            </NavItem>
                            {open[NavSection.ONEROSTER] && <React.Fragment>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} active={this.activeTab('/oneroster/dashboard')} to="/oneroster/dashboard" className="submenu"><FontAwesomeIcon icon="network-wired" className="nav_icon" />Översikt</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} active={this.activeTab('/oneroster/schoolunit')} to="/oneroster/schoolunit" className="submenu"><FontAwesomeIcon icon="school" className="nav_icon" />Skolor</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} active={this.activeTab('/oneroster/class')} to="/oneroster/class" className="submenu"><FontAwesomeIcon icon="calendar-alt" className="nav_icon" />Avsnitt</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} active={this.activeTab('/oneroster/user')} to="/oneroster/user" className="submenu"><FontAwesomeIcon icon="user" className="nav_icon" />Användare</NavLink>
                                </NavItem>
                            </React.Fragment>}
                        </React.Fragment>}


                        <NavItem onClick={() => this.toggleMenu(NavSection.CONFIGURATION)}>
                            <NavLink href="#" className={active(NavSection.CONFIGURATION)}>Konfiguration<span className="open">{symbols(NavSection.CONFIGURATION)}</span></NavLink>
                        </NavItem>
                        {open[NavSection.CONFIGURATION] && <React.Fragment>
                            <NavItem>
                                <NavLink tag={RouterNavLink} active={this.activeTab('/module')} to="/module/" className="submenu"><FontAwesomeIcon icon="file-import" className="nav_icon" />Moduler</NavLink>
                            </NavItem>
                            {isAdmin &&
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to="/auditlog/" className="submenu"><FontAwesomeIcon icon="list" className="nav_icon" />Granskningslogg</NavLink>
                                </NavItem>
                            }
                        </React.Fragment>}


                        <NavItem>
                            <SubFooter className="nav" />
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

const mapStateToProps = state => ({
    authAuthority: state.auth.authority,
    open: state.menu.open,
    isAdmin: state.me.isAdmin,
    myModuleTypes: state.module.myModuleTypes,
});

export default withRouter(connect(mapStateToProps, { signOut, ToggleMenu })(NavMenu));
