import React from 'react';
import Loading from '../../misc/Loading';
import { Link } from 'react-router-dom'
import * as Helper from '../../../util/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusCode from '../../../util/StatusCode';
import { Result, CoreModule, CoreModuleStatus, CoreModuleTrigger } from '../../../models/CoreModels';


interface HomeModulesProps {
    modules: Result<CoreModule[]>
    type: number
}

const HomeModules = (props: HomeModulesProps) => {

    const icon = (status: CoreModuleStatus | undefined, active: boolean) => {
        if (!status || !active) {
            return null;
        }

        switch (status.iconState) {
            case 1: return <FontAwesomeIcon icon="check-circle" className="green icon_active" title="Allt har gått bra" />;
            case 2: return <FontAwesomeIcon icon="exclamation-circle" className="yellow icon_active" title="Varning" />;
            case 3: return <FontAwesomeIcon icon="times-circle" className="red icon_active" title="Error" />;
            case 4: return <FontAwesomeIcon icon="times-circle" className="red icon_active" title="Error" />;
            default: return null;
        }
    }

    const renderActive = (active: boolean) => {
        if (active) {
            return <span title="Modulen är aktiv"><FontAwesomeIcon icon="toggle-on" className="icon green" /><span className="icon_text">Aktiv</span></span>;
        }
        return <span title="Modulen är inaktiv"><FontAwesomeIcon icon="toggle-off" className="icon red" /><span className="icon_text">Inaktiv</span></span>;
    }

    const renderTriggers = (triggers?: CoreModuleTrigger[], nextStart?: string) => {
        if (!triggers) {
            return "-";
        } if (triggers.length > 1) {
            return "Flera schemaläggningar";
        } else if (triggers.length === 1) {
            if (triggers[0].triggerType === 2) {
                return (<span>Efter <Link to={`/module/${triggers[0].runAfterModuleId}/configuration`}>{triggers[0].runAfterModuleTitle}</Link></span>);
            } else if (triggers[0].triggerType === 1) {
                if (nextStart) {
                    return Helper.formatDate(nextStart);
                }
                return "Tidsbaserad schemaläggning";
            }
        } else {
            return "-";
        }
    }

    const renderLatestRun = (status?: CoreModuleStatus) => {
        if (status) {
            var isRunning = status.startedAt && !status.completedAt;
            if (isRunning) {
                return <span title="Modulen körs"><FontAwesomeIcon icon="sync-alt" className="icon green" /><span className="icon_text">Körs</span></span>;
            } else if (status.isStartRequested) {
                return <span title="Modulen är förfrågad att starta"><FontAwesomeIcon icon="sync-alt" className="icon green" /><span className="icon_text">Startar</span></span>;
            } else {
                return Helper.formatDate(status.completedAt)
            }
        }
        return '-';
    }

    const module = (module: CoreModule) => {
        return (
            <div className="dashboard_module" key={module.id}>
                <Link to={module.runnable ? `/module/${module.id}/status` : `/module/${module.id}/configuration`} key={module.id}>
                    <div className="dashboard_top">
                        <h6>{module.title}</h6>
                        {icon(module.status, module.active)}
                    </div>
                </Link >
                <div className="dashboard_content">
                    {renderActive(module.active)}
                    {module.status?.isInQuarantine && <span title="Modulen är satt i karantän"><FontAwesomeIcon icon="stop-circle" className="icon red" /><span className="icon_text">Karantän</span></span>}

                    <p>Kördes senast: {renderLatestRun(module.status)}</p>
                    <p>Nästa körning: {renderTriggers(module.moduleTriggers, module.nextScheduledStart)}</p>
                </div>
            </div>
        );
    }

    const moduleList = (modules: Result<CoreModule[]>, type: number) => {
        switch (modules.code) {
            case StatusCode.PENDING: return <Loading />;
            case StatusCode.ERROR: return <p>Kunde inte hämta moduler</p>;
            default: return modules.data.filter(m => m.moduleType === type).map(m => module(m));
        }
    };

    return (<>
        {moduleList(props.modules, props.type)}
    </>);
}

export default HomeModules;
