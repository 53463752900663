import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CoreTracking } from "../../../models/CoreTracking";
import { RequestState } from "../../../models/RequestState";
import { GetUserTrackings, UserState } from "../../../store/UserAction";
import TrackingsModule from "../components/TrackingsModule";

interface State {
    users: UserState
}

const UserViewTrackings = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const trackings = useSelector<State, RequestState<CoreTracking[]>>(state => state.users.userTrackings);

    useEffect(() => {
        if (userId !== undefined) {
            GetUserTrackings(userId)(dispatch);
        }
    }, [dispatch, userId]);

    return (<>
        <TrackingsModule
            trackings={trackings}
            noResMsg="Den här personen saknar källor"
        />
    </>);
}

export default UserViewTrackings;
