import React from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Input, Card, CardBody, CardHeader, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import { getSystemNames } from '../../../../store/ModuleAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../../../misc/Loading'
import CheckboxListItem from '../../../misc/CheckboxListItem';
import IntInput from '../../../misc/IntInput';
import NullableIntInputCheckboxText from '../../../misc/NullableIntInputCheckboxText';
import InstanceClient from '../../../../services/InstanceClient';
import Copy from '../../../misc/Copy';
import StatusCode from '../../../../util/StatusCode';

class ExportOneRosterConfiguration extends ConfigurationComponent {
    state = {
        open: true,
        oneToOneCheck: this.props.configuration.oneToOneModuleType ? true : false,
        clientId: this.props.configuration.clientId,
        clientSecret: this.props.configuration.clientSecret,
        saving: false,
        saved: false,
        error: false
    };

    componentDidMount() {
        this.props.getSystemNames();
    }

    validate(configuration, active) {
        const validation = [];

        if (this.state.oneToOneCheck && !configuration.oneToOneModuleType) {
            validation.push('oneToOne');
        }
        if (configuration.archiveGracePeriod < 0) {
            validation.push('archiveGracePeriod')
        }
        if (configuration.archiveGracePeriod === 0 && configuration.deleteGracePeriod !== null && configuration.deleteGracePeriod < 14) {
            validation.push('deleteGracePeriod')
        }
        if (configuration.deleteGracePeriod < 0) {
            validation.push('deleteGracePeriod')
        }
        
        return validation;
    }

    warnings(update) {
        const warningMessages = [];

        if (update['class'] === false) {
            warningMessages.push('Om du tar bort klass kommer teamen kopplade till dessa att arkiveras')
        }

        if (update['course'] === false) {
            warningMessages.push('Om du tar bort aktivitet kommer teamen kopplade till dessa att arkiveras')
        }

        if (update['department'] === false) {
            warningMessages.push('Om du tar bort avdelning kommer teamen kopplade till dessa att arkiveras')
        }

        if (update['oneToOneModuleType']) {
            warningMessages.push('Id kommer inte skickas vidare för redan skapade team')
        }

        return warningMessages;
    }

    updateOneRosterConfiguration = (prop, value) => {
        var newUpdates = { ...this.props.updates };
        const oneToOneValue = (prop === 'oneToOneCheck') ? value : this.state.oneToOneCheck;

        if (prop === 'oneToOneCheck') {
            this.setState({ oneToOneCheck: !this.state.oneToOneCheck })
        }

        if (this.props.configuration[prop] === value) {
            delete newUpdates[prop];
        } else {
            newUpdates[prop] = value;
        }

        if (prop === 'oneToOneCheck' && value === false) {
            if (this.props.configuration.oneToOneModuleType) {
                newUpdates.oneToOneModuleType = '';
            }
            if (newUpdates.oneToOneModuleType) {
                delete newUpdates.oneToOneModuleType;
            }
        }

        if ((prop === 'oneToOneCheck' && value === true) && (this.props.configuration.oneToOneModuleType)) {
            newUpdates.oneToOneModuleType = this.props.configuration.oneToOneModuleType;
        }
        if (prop === 'oneToOneCheck') {
            delete newUpdates.oneToOneCheck;
        }

        this.notifyUpdates(newUpdates);
        this.updateData(newUpdates, oneToOneValue);
    }

    updateClientCredentials = (clientId, clientSecret) => {
        this.setState({
            clientId: clientId,
            clientSecret: clientSecret,
            saving: false,
            saved: true
        })
    }

    generateClientCredentials = (id) => {
        if (window.confirm('Denna operation kommer innebära att SDS inte kommer komma åt OneRoster tills dess att de nya upgifterna matats in där.')) {
            this.setState({ saving: true })

            InstanceClient.post(`/api/core/oneRosterConfiguration/${id}/generateClientCredential`)
                .then(res => this.updateClientCredentials(res.data.clientId, res.data.clientSecret))
                .catch(err => this.setState({ error: true }))
        }
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const { oneToOneCheck, saving, saved, error } = this.state;
        const validation = this.validate(configuration, this.props.active);
        const location = window.location.origin;
        const webUrl = `${location}/ims/oneroster/v1p1`
        const tokenUrl = `${location}/token`

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <FormGroup>
                            <h5>Typ av team</h5>
                            <CheckboxListItem text='Klass'
                                value={configuration.class}
                                onChange={(e) => this.updateOneRosterConfiguration('class', e.target.checked)}
                                disabled={this.props.disableInput}
                            />
                            <CheckboxListItem text='Aktivitet'
                                value={configuration.course}
                                onChange={(e) => this.updateOneRosterConfiguration('course', e.target.checked)}
                                disabled={this.props.disableInput}
                            />
                            <CheckboxListItem text='Avdelning'
                                value={configuration.department}
                                onChange={(e) => this.updateOneRosterConfiguration('department', e.target.checked)}
                                disabled={this.props.disableInput}
                            />
                        </FormGroup>
                        <FormGroup>
                            <h5>Provisionering av Microsoft Teams</h5>
                            <CheckboxListItem text='Framtvinga provisionering av Microsoft Teams'
                                value={configuration.forceTeamProvision}
                                onChange={(e) => this.updateOneRosterConfiguration('forceTeamProvision', e.target.checked)}
                                disabled={this.props.disableInput}
                            />
                        </FormGroup>

                        <FormGroup>
                            <h5>Livscykelhantering</h5>
                            <IntInput
                                title="Grace period:"
                                name="archiveGracePeriod"
                                value={configuration.archiveGracePeriod}
                                disabled={this.props.disableInput}
                                updateValue={(n, v) => this.updateOneRosterConfiguration(n, v)}
                                invalidFeedback="Ange en giltig period"
                                invalid={validation.indexOf('archiveGracePeriod') !== -1}
                            />

                            <NullableIntInputCheckboxText
                                name="deleteGracePeriod"
                                value={configuration.deleteGracePeriod}
                                disabled={this.props.disableInput}
                                title="Radera arkiverade team efter dagar"
                                invalidFeedback={configuration.archiveGracePeriod === 0 ? "Antal dagar måste vara minst 14" : "Ange en giltig period"}
                                invalid={validation.indexOf('deleteGracePeriod') !== -1}
                                defaultValue={1}
                                onChange={(k, v) => this.updatePropertyValue(k, v)}
                                checkboxTitle="Ingen radering"
                            />
                        </FormGroup>

                        <FormGroup>
                            <h5>SDS konfiguration</h5>
                            <p><span className="bold">Web Access URL: </span>{webUrl}<Copy value={webUrl} /></p>
                            <p><span className="bold">Klient id (Client Id): </span>{this.state.clientId}<Copy value={this.state.clientId}/></p>
                            <p><span className="bold">Klient hemlighet (Client Secret): </span>{this.state.clientSecret}<Copy value={this.state.clientSecret} /></p>
                            <p><span className="bold">Token endpoint URL: </span>{tokenUrl}<Copy value={tokenUrl} /></p>

                            <div className="edit_module">
                                <Button className="flowsync-btn" disabled={this.props.disableInput} onClick={() => this.generateClientCredentials(configuration.id)}>Generera ny hemlighet</Button>
                                <div style={{ marginLeft: '1rem', display: 'inline-block' }}>
                                    {saving && <Loading />}
                                    {error && <span style={{ marginLeft: '0.5rem' }}>Något gick fel</span>}
                                    {saved && <FontAwesomeIcon icon="check" style={{ fontSize: '22px', verticalAlign: 'middle', color: '#27807d' }} />}
                                </div>
                            </div>

                        </FormGroup>
                        <FormGroup>
                            <h5>Id från elevregister</h5>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" id="oneToOneCheck" checked={oneToOneCheck} disabled={this.props.disableInput} onChange={(e) => this.updateOneRosterConfiguration('oneToOneCheck', e.target.checked)} />
                                        Skicka vidare id från elevregister
                                    </Label>
                            </FormGroup>

                            <FormGroup>
                                <Label for="oneToOne">Välj importmodultyp där id:n hämtas</Label>
                                <Input type="select" name="oneToOne" id="oneToOne" onChange={(e) => this.updateOneRosterConfiguration('oneToOneModuleType', e.target.value)} disabled={!oneToOneCheck || this.props.disableInput} value={configuration.oneToOneModuleType || ''} invalid={validation.indexOf('oneToOne') !== -1} >
                                    <option value='' disabled>Nya SIS-ID:n kommer att skapas automatiskt</option>
                                    {this.props.systemNames.code === StatusCode.COMPLETE && this.props.systemNames.data.map((type, i) => <option key={i} value={type}>{type}</option>)}
                                </Input>
                                <FormFeedback>Välj modultyp för att skicka vidare id från elevregister</FormFeedback>
                            </FormGroup>
                        </FormGroup>
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
};

const mapStateToProps = state => ({
    systemNames: state.module.systemNames
})

export default connect(mapStateToProps, { getSystemNames })(ExportOneRosterConfiguration);
