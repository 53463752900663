import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppTokenState, microsoftDelegated, validateAppToken } from '../../../../store/AppTokenAction';
import Loading from '../../../misc/Loading';
import * as AppTokenUtil from '../../../../util/AppTokenUtil';
import { Button, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CoreAppToken, CoreAppTokenValidation } from '../../../../models/CoreAppToken';
import { RequestState } from '../../../../models/RequestState';
import StatusCode from '../../../../util/StatusCode';
import Copy from '../../../misc/Copy';

interface AppTokenRootState {
    token: AppTokenState
}

interface AppTokenMicrosoftApplicationProps {
    appToken: CoreAppToken,
    disableInput: boolean
}

const AppTokenMicrosoftApplication = (props: AppTokenMicrosoftApplicationProps) => {

    const appTokenValidationRequestState = useSelector<AppTokenRootState, RequestState<CoreAppTokenValidation>>(x => x.token.appTokenValidation);
    const dispatch = useDispatch();
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const login = async (appTokenValidate: CoreAppTokenValidation) => {
        let uri = appTokenValidate.flowSyncUXUri ?? '';
        let login = await AppTokenUtil.authWindow(uri);

        if (login.code) {
            setIsAuthenticating(true);
            await generateMicrosoftDelegated(props.appToken.id, login.code)
            setIsAuthenticating(false);
        }

    }

    const generateMicrosoftDelegated = async (appTokenId: string, code: string) => {
        await microsoftDelegated(appTokenId, code)(dispatch);
        await validateAppToken(props.appToken.id);
    }

    if (appTokenValidationRequestState.code === StatusCode.NONE || appTokenValidationRequestState.code === StatusCode.PENDING) {
        return (<Loading />);
    }

    if (appTokenValidationRequestState.code === StatusCode.ERROR) {
        return (
            <div>
                <p>Misslyckades</p>
                <p>Servern svarade med '{appTokenValidationRequestState.error.code}'</p>
            </div>)
    }

    const appTokenValidation = appTokenValidationRequestState.data;

    if (!appTokenValidation.valid) {
        return (
            <div>
                <p><FontAwesomeIcon icon="triangle-exclamation" className="apptoken-configuration__status-icon--warning" />Kontot behöver konfigureras</p>
                <div>
                    <div>
                        <strong>Steg 1: Godkänn applikationen (kräver högre behörighet)</strong>
                        <div className="push--top">
                            <Label for="copyLinkUri">Dela nedanstående länk med en administratör: <Copy value={appTokenValidation.copyLinkUri ?? ''} /></Label>
                            <Input name="copyLinkUri" disabled value={appTokenValidation.copyLinkUri} />
                        </div>
                    </div>
                    <div className="push--top">
                        <strong>Steg 2: Logga in med service konto</strong>
                        <div className="apptoken-configuration__button-container">
                            <Button color="primary" disabled={props.disableInput} onClick={() => login(appTokenValidation)}>Logga in</Button>
                            {isAuthenticating && <Loading className="push--left" />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (<div>
        <div className='apptoken-configuration__status'>
            <FontAwesomeIcon icon="check" className="apptoken-configuration__status-icon--ok" />Kontot är konfigurerat
        </div>
        <div className='apptoken-configuration__status'>
            <FontAwesomeIcon icon="user" className="apptoken-configuration__status-icon" />Servicekonto: {props.appToken.userName}
        </div>
    </div>);
}

export default AppTokenMicrosoftApplication;
