import React from 'react';
import { Row } from 'reactstrap';
import NavMenu from './NavMenu';
import Top from './Top';

export default props => <div>
    <Row className="layout">
        <div className="nav-left">
            <NavMenu activeSection={props.navSection} />
        </div>
        <div className="children">
            <Top />
            {props.children}
        </div>
    </Row>
</div>;
    